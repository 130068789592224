import { useContext, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Loading from "./Loading";
import { DashUserContext } from "../context";

const Clients = ({ activation, active, loading, idLoad, LoadState, setLoadState }) => {
    const [dataClient, setDataClient] = useState([])
    const [filterDataClient, setFilterDataClient] = useState([])
    const [searchUserNameClient, setSearchUserNameClient] = useState('')
    const navigate = useNavigate()
    const token = window.localStorage.getItem('token')
    const { proxy } = useContext(DashUserContext)
    //############################################

    useEffect(() => {
        getAllClient()
        if (!token) {
            navigate('/')
        }
    }, [active])

    useEffect(() => {
        const result = dataClient.filter(item => {
            return item.fullName.toLowerCase().match(searchUserNameClient.toLowerCase())
        })
        setFilterDataClient(result)
    }, [searchUserNameClient])

    //############################################

    const getAllClient = () => {
        setLoadState(true)
        let headers = {
            Authorization: `Bearer ${window.localStorage.getItem('token')}`,
            "Content-Type": "application/json"
        }
        axios.get(proxy + '/api/Users/GetAllClients', { headers }).then(res => {
            setDataClient(res.data)
            setFilterDataClient(res.data)
            setLoadState(false)
        }).catch(err => {
            setLoadState(false)
        })
    }

    //############################################

    const columns = [
        {
            name: 'Client Name',
            selector: row => row.fullName
        },
        {
            name: 'Email',
            selector: row => row.email
        },
        {
            name: 'Address',
            selector: row => row.address
        },
        {
            name: 'Phone Number',
            selector: row => row.phoneNumber
        },
        {
            name: 'Status',
            selector: row => row.isActive === true ? 'Active' : 'In Active'
        },
        {
            name: 'action',
            cell: row => <>
                {row.id === idLoad ? <button className="btn btn-primary" type="button" disabled>
                    <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                    Loading...
                </button> :
                    <>
                        <button className="btn btn-primary" onClick={() => activation(row.id)}> {row.isActive === true ? 'In Active' : 'Active'} </button>
                        <Link to={'/Clients/' + row.id} className="btn btn-primary"> Details </Link>
                    </>
                }
            </>
        }
    ]

    //############################################

    return (
        <>
            {
                LoadState ? <Loading /> : <DataTable
                    columns={columns}
                    data={filterDataClient}
                    pagination
                    title='Client List'
                    fixedHeader
                    // selectableRows
                    // onSelectedRowsChange={(item) => console.table(item.selectedRows)}
                    selectableRowsHighlight
                    highlightOnHover
                    subHeader
                    subHeaderComponent={<input
                        type='text'
                        placeholder="Search"
                        className="form-control"
                        value={searchUserNameClient}
                        onChange={(e) => setSearchUserNameClient(e.target.value)}
                    />}
                />
            }
        </>
    );
}

export default Clients;