import axios from 'axios';
import { useContext, useState } from 'react';
import toast from 'react-hot-toast';
import { useRoutes } from 'react-router-dom';
import './App.css';
import Navbar from './components/Navbar';
import Sidbar from './components/Sidbar';
import Cars from './pages/Cars';
import Clients from './pages/Clients';
import Client from './pages/Client';
import Document from './pages/Document';
import Driver from './pages/Driver';
import Drivers from './pages/Drivers';
import Home from './pages/Home';
import Loading from './pages/Loading';
import Login from './pages/Login';
import Offer from './pages/Offer';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Profile from './pages/Profile';
import Roles from './pages/Roles';
import Slider from './pages/Slider';
import TermsAndCondition from './pages/TermsAndCondition';
import Users from './pages/Users';
import { DashUserContext } from './context';

function App() {
  const [active, setActive] = useState(false)
  const [loading, setLoading] = useState(false)
  const [idLoad, setIdLoad] = useState('')
  const [LoadState, setLoadState] = useState(false)
  const { proxy } = useContext(DashUserContext)
  //##############################################

  const activation = (id) => {
    setIdLoad(id)
    setLoading(true)
    let headers = {
      Authorization: `Bearer ${window.localStorage.getItem('token')}`,
      "Content-Type": "application/json"
    }
    axios.post(`${proxy}/api/Users/ChangeUserActivity/${id}`, '', { headers }).then(res => {
      setActive(!active)
      setLoading(false)
      setIdLoad('')
      toast.success(res.data.messageEn)
    }).catch(err => {
      setLoading(false)
      setIdLoad('')
      toast.error(err.response.data.messageEn)
    })
  }

  //##############################################

  const activeDriverAttat = (id, statusAttat) => {
    setIdLoad(id)
    setLoading(true)
    let headers = {
      Authorization: `Bearer ${window.localStorage.getItem('token')}`,
      "Content-Type": "application/json"
    }
    axios.get(`${proxy}/api/Users/ChangeAttatchmentStatus/${id}/${statusAttat}`, { headers }).then(res => {
      setLoading(false)
      setActive(!active)
      toast.success(res.data.messageEn)
    }).catch(err => {
      setLoading(false)
      toast.error(err.response.data.messageEn)
    })
  }

  //##############################################

  const routes = useRoutes([
    {
      path: '/Clients/:id',
      element: <Client loading={loading} activation={activation} active={active} setActive={setActive} idLoad={idLoad} LoadState={LoadState} setLoadState={setLoadState} />
    },
    {
      path: '/Clients',
      element: <Clients loading={loading} activation={activation} active={active} setActive={setActive} idLoad={idLoad} LoadState={LoadState} setLoadState={setLoadState} />
    },
    {
      path: '/Drivers',
      element: <Drivers loading={loading} activation={activation} active={active} setActive={setActive} idLoad={idLoad} LoadState={LoadState} setLoadState={setLoadState} />
    },
    {
      path: '/Drivers/:id',
      element: <Driver activeDriverAttat={activeDriverAttat} idLoad={idLoad} loading={loading} setLoadState={setLoadState} LoadState={LoadState} active={active} />
    },
    {
      path: '/Users',
      element: <Users loading={loading} activation={activation} active={active} setActive={setActive} idLoad={idLoad} LoadState={LoadState} setLoadState={setLoadState} />
    },
    {
      path: '/Cars',
      element: <Cars idLoad={idLoad} setIdLoad={setIdLoad} LoadState={LoadState} setLoadState={setLoadState} />
    },
    {
      path: '/Roles',
      element: <Roles idLoad={idLoad} setIdLoad={setIdLoad} LoadState={LoadState} setLoadState={setLoadState} />
    },
    {
      path: '/Slider',
      element: <Slider idLoad={idLoad} setIdLoad={setIdLoad} LoadState={LoadState} setLoadState={setLoadState} />
    },
    {
      path: '/Offer',
      element: <Offer idLoad={idLoad} setIdLoad={setIdLoad} LoadState={LoadState} setLoadState={setLoadState} />
    },
    {
      path: '/Document',
      element: <Document idLoad={idLoad} setIdLoad={setIdLoad} LoadState={LoadState} setLoadState={setLoadState} />
    },
    {
      path: '/Home',
      element: <Home LoadState={LoadState} setLoadState={setLoadState} />
    },
    {
      path: '/Loading',
      element: <Loading LoadState={LoadState} setLoadState={setLoadState} />
    },
    {
      path: '/TermsAndCondition',
      element: <TermsAndCondition LoadState={LoadState} setLoadState={setLoadState} />
    },
    {
      path: '/PrivacyPolicy',
      element: <PrivacyPolicy LoadState={LoadState} setLoadState={setLoadState} />
    },
    {
      path: '/Profile',
      element: <Profile loading={loading} LoadState={LoadState} setLoadState={setLoadState} />
    },
    {
      path: '/',
      element: <Login LoadState={LoadState} setLoadState={setLoadState} />
    }
  ])

  //############################################

  return (
    <>
      {
        window.location.hash === '#/' || window.location.hash === '' ? <Login /> : <>
          <Navbar />
          <div className="content">
            <div className="row">
              <Sidbar />
              <div className="col-12 col-sm-9 col-md-9 col-lg-10 cont">
                <div className="client">
                  {routes}
                </div>
              </div>
            </div>
          </div>
        </>
      }
    </>
  )
}

export default App;
