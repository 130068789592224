import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import toast from 'react-hot-toast';
import Loading from "./Loading";
import { DashUserContext } from "../context";

const Login = () => {
    const [email, setEmail] = useState('')
    const [Password, setPassword] = useState('')
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const token = window.localStorage.getItem('token')
    const { proxy } = useContext(DashUserContext)
    //############################################

    useEffect(() => {
        if (token) {
            navigate('/Home')
        }
    }, [])

    //############################################

    const handelLogin = (e) => {
        setLoading(true)
        e.preventDefault()
        if (email === '') {
            toast.error('Please Enter Your Email')
            setLoading(false)
        } else if (Password === '') {
            toast.error('Please Enter The Password')
            setLoading(false)
        } else {
            axios.post(proxy + '/api/Account/Login', {
                email: email,
                password: Password
            }).then(res => {
                setLoading(false)
                window.localStorage.setItem('token', res.data.token)
                navigate('/Home')
            }).catch(err => {
                setLoading(false)
                toast.error(err.response.data.message)
            })
        }
    }

    //############################################

    return (
        <>
            {
                loading ? <Loading /> :
                    <div className="login">
                        <div className="LoginCont">
                            <h3>Login</h3>
                            <form onSubmit={handelLogin}>
                                <input type='text' placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
                                <input type='password' placeholder="Password" value={Password} onChange={(e) => setPassword(e.target.value)} />
                                <button type='submit' disabled={loading}>Login</button>
                            </form>
                        </div>
                    </div>
            }
        </>
    );
}

export default Login;