import axios from "axios";
import { useEffect, useState } from "react";
import { createContext } from "react";

export const DashUserContext = createContext();

const DashUser = (Props) => {
    const [userImage, setUserImage] = useState('')
    // const proxy = 'https://naql.code-iss.com/NaqlDashboard'
    // const proxy = 'https://egy.lorryapp.net/dashboardapi'
    const proxy = 'https://ksa.lorryapp.net/dashboardapi'
    useEffect(() => {
        getUserImageNav()
    }, [])

    const getUserImageNav = () => {
        let headers = {
            Authorization: `Bearer ${window.localStorage.getItem('token')}`,
            "Content-Type": "application/json"
        }
        axios.get(proxy + '/api/Account/UserProfile', { headers }).then(res => {
            setUserImage(res.data.userImage)
        }).catch(err => {
        })
    }
    return (
        <DashUserContext.Provider value={{ userImage, getUserImageNav, proxy }}>
            {Props.children}
        </DashUserContext.Provider>
    );
}

export default DashUser;