/* eslint-disable jsx-a11y/alt-text */
import axios from "axios";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useContext, useState } from "react";
import { useEffect } from "react";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import Navbar from "../components/Navbar";
import Form from 'react-bootstrap/Form';
import toast from "react-hot-toast";
import Loading from "./Loading";
import { DashUserContext } from "../context";

const Cars = ({ idLoad, setIdLoad, LoadState, setLoadState }) => {

    const [dataCars, setDataCars] = useState([])
    const [filterDataCars, setFilterDataCars] = useState([])
    const [searchCars, setSearchCars] = useState('')
    const [showEdit, setShowEdit] = useState(false);
    const [showAdd, setShowAdd] = useState(false);
    const [typeCar, setTypeCar] = useState('')
    const [typeArCar, setTypeArCar] = useState('')
    const [ImageCar, setImageCar] = useState('')
    const [idCar, setIdCar] = useState('')
    const [imageAddCar, setImageAddCar] = useState('')
    const [loading, setLoading] = useState(false)
    const handleCloseEdit = () => setShowEdit(false);
    const handleCloseAdd = () => setShowAdd(false);
    const handleShowEdit = (itemCar) => {
        setShowEdit(true)
        setTypeCar(itemCar.type)
        setTypeArCar(itemCar.typeAr)
        setImageCar(itemCar.image)
        setIdCar(itemCar.id)
    }
    const handleShowAdd = () => {
        setShowAdd(true)
        setTypeCar('')
        setTypeArCar('')
        setImageCar('')
    }
    const navigate = useNavigate()
    const token = window.localStorage.getItem('token')
    const { proxy } = useContext(DashUserContext)
    //############################################

    useEffect(() => {
        getAllCars()
        if (!token) {
            navigate('/')
        }
    }, [])

    useEffect(() => {
        const result = dataCars.filter(item => {
            return item.type.toLowerCase().match(searchCars.toLowerCase())
        })
        setFilterDataCars(result)
    }, [searchCars])

    //#############################################

    const getAllCars = () => {
        setLoadState(true)
        let headers = {
            Authorization: `Bearer ${window.localStorage.getItem('token')}`,
            "Content-Type": "application/json"
        }
        axios.get(proxy + '/api/Car/GetAllCars', { headers }).then(res => {
            setDataCars(res.data)
            setFilterDataCars(res.data)
            setLoadState(false)
        }).catch(err => {
            setLoadState(false)
        })
    }

    //############################################

    const editCar = () => {
        setLoading(true)
        let headers = {
            Authorization: `Bearer ${window.localStorage.getItem('token')}`,
            "Content-Type": "application/json"
        }
        let fd = new FormData()
        fd.append("type", typeCar)
        fd.append("typeAr", typeArCar)
        fd.append("image", imageAddCar === '' ? ImageCar : imageAddCar)
        axios.put(`${proxy}/api/Car/EditCar/${idCar}`, fd, { headers }).then(res => {
            handleCloseEdit()
            getAllCars()
            setLoading(false)
            toast.success(res.data.messageEn)
        }).catch(err => {
            toast.error(err)
            setLoading(false)
        })
    }

    //############################################

    const deleteCar = (id) => {
        setLoading(true)
        setIdLoad(id)
        let headers = {
            Authorization: `Bearer ${window.localStorage.getItem('token')}`,
            "Content-Type": "application/json"
        }
        axios.delete(`${proxy}/api/Car/DeleteCar/${id}`, { headers }).then(res => {
            getAllCars()
            setLoading(false)
            setIdLoad('')
            toast.success(res.data.messageEn)
        }).catch(err => {
            toast.error(err.response.data.messageEn)
            setLoading(false)
            setIdLoad('')
        })
    }

    //############################################

    const addCar = () => {
        setLoading(true)
        if (typeCar === '') {
            toast.error('Please Enter The Type Of Car')
            setLoading(false)
        } else if (typeArCar === '') {
            toast.error('Please Enter The Type Of Car AR')
            setLoading(false)
        } else if (imageAddCar === '') {
            toast.error('Please Choose A Picture')
            setLoading(false)
        } else {
            let headers = {
                Authorization: `Bearer ${window.localStorage.getItem('token')}`,
                "Content-Type": "application/json"
            }
            let fd = new FormData()
            fd.append("type", typeCar)
            fd.append("typeAr", typeArCar)
            fd.append("image", imageAddCar)
            axios.post(proxy + '/api/Car/AddCar', fd, { headers }).then(res => {
                console.log('yes')
                getAllCars()
                handleCloseAdd()
                setLoading(false)
                toast.success(res.data.messageEn)
            }).catch(err => {
                toast.error('no')
                setLoading(false)
            })
        }
    }

    //############################################

    const columns = [
        {
            name: 'Type',
            selector: row => row.type
        },
        {
            name: 'Type Ar',
            selector: row => row.typeAr
        },
        {
            name: 'Image',
            selector: row => <img width={70} height={70} src={`${proxy}/Resources/Cars/${row.image}`} />
        },
        {
            name: 'action',
            cell: row => <>
                {
                    row.id === idLoad ? <button className="btn btn-primary" type="button" disabled>
                        <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                        Loading...
                    </button> : <button className="btn btn-primary" onClick={() => deleteCar(row.id)}>Delete</button>
                }
                <button className="btn btn-primary" onClick={() => handleShowEdit(row)}>Edit</button>
            </>
        }
    ]

    //#############################################

    return (
        <div className="cars">
            <Modal show={showAdd} onHide={handleCloseAdd}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Car</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className="mb-3">
                        <Form.Label>Car Type *</Form.Label>
                        <Form.Control type="Text" placeholder="car Type" value={typeCar} onChange={(e) => setTypeCar(e.target.value)} />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Car TypeAr *</Form.Label>
                        <Form.Control type="Text" placeholder="car TypeAr" value={typeArCar} onChange={(e) => setTypeArCar(e.target.value)} />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Choose A Photo *</Form.Label>
                        <Form.Control id='file' accept='.jpg , .png , jpeg' type="file" onChange={(e) => setImageAddCar(e.target.files[0])} />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    {
                        loading ? <button className="btn btn-primary" type="button" disabled>
                            <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                            Loading...
                        </button> : <Button variant="primary" onClick={() => addCar()} disabled={loading}>
                            Add Car
                        </Button>
                    }
                </Modal.Footer>
            </Modal>
            <Modal show={showEdit} onHide={handleCloseEdit}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Car</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className="mb-3">
                        <Form.Label>car Type</Form.Label>
                        <Form.Control type="Text" placeholder="car Type" value={typeCar} onChange={(e) => setTypeCar(e.target.value)} />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>car TypeAr</Form.Label>
                        <Form.Control type="Text" placeholder="car TypeAr" value={typeArCar} onChange={(e) => setTypeArCar(e.target.value)} />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Choose A Photo</Form.Label>
                        <Form.Control id='file' accept='.jpg , .png , jpeg' type="file" onChange={(e) => setImageAddCar(e.target.files[0])} />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    {
                        loading ? <button className="btn btn-primary" type="button" disabled>
                            <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                            Loading...
                        </button> : <Button variant="primary" onClick={() => editCar()} disabled={loading} >
                            Save Changes
                        </Button>
                    }
                </Modal.Footer>
            </Modal>
            <Navbar />
            <Button className="btn btn-primary" onClick={() => handleShowAdd()} style={{ margin: '5px 0 16px' }}>Add Car</Button>
            {
                LoadState ? <Loading /> : <DataTable
                    columns={columns}
                    data={filterDataCars}
                    pagination
                    title='Cars List'
                    fixedHeader
                    // selectableRows
                    // onSelectedRowsChange={(item) => console.table(item.selectedRows)}
                    selectableRowsHighlight
                    highlightOnHover
                    subHeader
                    subHeaderComponent={<input
                        type='text'
                        placeholder="Search"
                        className="form-control"
                        value={searchCars}
                        onChange={(e) => setSearchCars(e.target.value)}
                    />}
                />
            }
        </div>
    );
}

export default Cars;