import axios from "axios"
import dayjs from "dayjs"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import ClientsChart from "../components/ClientsChart"
import DriverChart from "../components/DriverChart"
import OrdersStatistics from "../components/OrdersStatistics"
import PriceStatistics from "../components/PriceStatistics"
import Loading from "./Loading"


const Home = () => {
    const [loadState, setLoadState] = useState(false)
    const token = window.localStorage.getItem('token')
    const navigate = useNavigate()

    //############################################

    useEffect(() => {
        if (!token) {
            navigate('/')
        }
    }, [])

    //#############################################

    return (
        <>
            {
                loadState ? <Loading /> : <div className="home">
                    <div className="row">
                        <div className="col-12 col-lg-6 mb-3">
                            <h4> Drivers </h4>
                            <DriverChart setLoadState={setLoadState} />
                        </div>
                        <div className="col-12 col-lg-6 mb-3">
                            <h4> Clients </h4>
                            <ClientsChart setLoadState={setLoadState} />
                        </div>
                        <div className="col-12 mb-3">
                            <h4>  Orders  </h4>
                            <OrdersStatistics setLoadState={setLoadState} />
                        </div>
                        <div className="col-lg-12 mb-3">
                            <h4> Material Income </h4>
                            <PriceStatistics setLoadState={setLoadState} />
                        </div>

                    </div>
                </div>
            }
        </>
    );
}

export default Home;