/* eslint-disable jsx-a11y/alt-text */
import { useContext } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { Link, useNavigate } from "react-router-dom";
import { DashUserContext } from '../context';

const Navbar = () => {

    const { userImage } = useContext(DashUserContext)

    const navigate = useNavigate()

    //############################################

    const logout = () => {
        window.localStorage.removeItem('token')
        navigate('/')
    }

    //############################################

    return (
        <header className="navbarDash container-fluid d-flex justify-content-between align-items-center">
            <img className='logo' src='/assets/Images/lorry-logo-white.png' />
            {/* <button className='logout' onClick={logout}> Logout </button> */}
            <DropdownButton id="dropdown-basic-button" title={<img className='imgClick' src={userImage} />}>
                <Link className='dropdown-item' to='/Profile'> Profile </Link>
                <Dropdown.Item onClick={logout}> Logout </Dropdown.Item>
            </DropdownButton>
        </header>

    );
}

export default Navbar;