import { TextField } from "@mui/material"
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import axios from "axios"
import dayjs from "dayjs"
import { useContext, useEffect, useState } from "react"
import ReactApexChart from "react-apexcharts"
import { Stack } from "react-bootstrap"
import { DashUserContext } from "../context"

const OrdersStatistics = ({ setLoadState }) => {
    const month = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec']
    const { proxy } = useContext(DashUserContext)
    useEffect(() => {
        GetOrdersStatistics()
    }, [])

    //####################################################

    const [ordersStatistics, setOrdersStatistics] = useState({
        series: [{
            name: 'Drivers',
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        }, {
            name: 'Clients',
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        }],
        options: {
            chart: {
                height: 350,
                type: 'bar'
            },
            dataLabels: {
                enabled: true
            },
            stroke: {
                curve: 'smooth'
            },
            xaxis: {
                categories: month
            },
        },
    })

    const GetOrdersStatistics = () => {
        setLoadState(true)
        let headers = {
            Authorization: `Bearer ${window.localStorage.getItem('token')}`,
            "Content-Type": "application/json"
        }
        axios.get(proxy + '/api/Users/GetCompleteOrdersStatistics', { headers }).then(res => {
            setOrdersStatistics({
                series: [{
                    name: 'Drivers',
                    data: res.data
                }],
                options: {
                    chart: {
                        height: 350,
                        type: 'bar'
                    },
                    dataLabels: {
                        enabled: true
                    },
                    stroke: {
                        curve: 'smooth'
                    },
                    xaxis: {
                        categories: month
                    },
                },
            })
            setLoadState(false)
        }).catch(err => {
            setLoadState(false)
        })
    }

    //####################################################

    const [orderYear, setOrderYear] = useState(dayjs(new Date()))
    const GetOrderStatisticsYear = (value) => {
        setOrderYear(value)
        let headers = {
            Authorization: `Bearer ${window.localStorage.getItem('token')}`,
            "Content-Type": "application/json"
        }
        axios.get(proxy + '/api/Users/GetCompleteOrdersStatistics/' + value.$y, { headers }).then(res => {
            setOrdersStatistics({
                series: [{
                    name: 'Clients',
                    data: res.data
                }],
                options: {
                    chart: {
                        height: 350,
                        type: 'bar'
                    },
                    dataLabels: {
                        enabled: true
                    },
                    stroke: {
                        curve: 'smooth'
                    },
                    xaxis: {
                        categories: month
                    },
                },
            })
        }).catch(err => {
        })
    }

    //####################################################

    return (
        <div id="chart" className='chartdriver'>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Stack spacing={3}>
                    <DatePicker
                        views={['year']}
                        // label="Drivers"
                        value={orderYear}
                        onChange={(newValue) => {
                            GetOrderStatisticsYear(newValue)
                        }}
                        renderInput={(params) => <TextField {...params} helperText={null} />}
                    />
                </Stack>
            </LocalizationProvider>
            <ReactApexChart options={ordersStatistics.options} series={ordersStatistics.series} type="bar" height={350} />
        </div>
    );
}

export default OrdersStatistics;