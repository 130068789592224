import axios from "axios";
import { useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Navigate } from "react-router-dom";
import { DashUserContext } from "../context";
import Loading from "./Loading";

const PrivacyPolicy = ({ LoadState, setLoadState }) => {
    const [provisionsEn, setProvisionsEn] = useState('')
    const [provisionsAr, setProvisionsAr] = useState('')
    const [provisionsId, setProvisionsId] = useState('')
    const [provisionsErr, setProvisionsErr] = useState('')
    const token = window.localStorage.getItem('token')
    const { proxy } = useContext(DashUserContext)
    //############################################

    useEffect(() => {
        getTerms()
        if (!token) {
            Navigate('/')
        }
    }, [])

    //########################################################

    const getTerms = () => {
        setLoadState(true)
        let headers = {
            Authorization: `Bearer ${window.localStorage.getItem('token')}`,
            "Content-Type": "application/json"
        }
        axios.get(`${proxy}/api/TermsAndPivacy/GetPrivacyAndPolicy`, { headers }).then(res => {
            setLoadState(false)
            setProvisionsEn(res.data.text)
            setProvisionsErr(res.data.text)
            setProvisionsId(res.data.id)
            setProvisionsAr(res.data.textAr)
        }).catch(err => {
            console.log(err)
            setLoadState(false)
        })
    }

    //########################################################

    const addTerms = () => {
        let headers = {
            Authorization: `Bearer ${window.localStorage.getItem('token')}`,
            "Content-Type": "application/json"
        }
        axios.post(`${proxy}/api/TermsAndPivacy/AddPrivacyAndPolicy`, { text: provisionsEn, textAr: provisionsAr }, { headers }).then(res => {
            getTerms()
        }).catch(err => {
            console.log(err)
        })
    }

    //########################################################

    const editTerms = () => {
        let headers = {
            Authorization: `Bearer ${window.localStorage.getItem('token')}`,
            "Content-Type": "application/json"
        }
        axios.put(`${proxy}/api/TermsAndPivacy/UpdatePrivacyAndTermsRecord/${provisionsId}`, { text: provisionsEn, textAr: provisionsAr }, { headers }).then(res => {
            getTerms()
            toast.success(res.data.messageEn)
        }).catch(err => {
            console.log(err)
            toast.error(err.response.data.messageEn)
        })
    }

    //############################################

    return (
        <>
            {
                LoadState ? <Loading /> : <div className="Terms">
                    <div className="mb-3">
                        <label htmlFor="exampleFormControlTextarea1" className="form-label mb-4"> Privacy Policy Einglish </label>
                        <textarea value={provisionsEn} onChange={(e) => setProvisionsEn(e.target.value)} className="form-control" id="exampleFormControlTextarea1" rows="5" placeholder="Enter the Privacy Policy"></textarea>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="exampleFormControlTextarea1" className="form-label mb-4"> Privacy Policy Arabic </label>
                        <textarea value={provisionsAr} onChange={(e) => setProvisionsAr(e.target.value)} className="form-control" id="exampleFormControlTextarea1" rows="5" placeholder="Enter the Privacy Policy"></textarea>
                    </div>
                    <button className="btn btn-primary" disabled={provisionsEn !== ''} onClick={() => addTerms()} style={{ width: '100px' }}> Add </button>
                    <button className="btn btn-primary" onClick={() => editTerms()} style={{ width: '100px' }}> Edit </button>
                </div>
            }
        </>
    );
}

export default PrivacyPolicy;