/* eslint-disable jsx-a11y/alt-text */
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import toast from "react-hot-toast";
import { Navigate } from "react-router-dom";
import Loading from "./Loading";
import { DashUserContext } from './../context/index';

const Profile = ({ setLoadState, LoadState }) => {
    const { getUserImageNav, proxy } = useContext(DashUserContext)
    const [fName, setFName] = useState('')
    const [fullName, setFullName] = useState('')
    const [lName, setLName] = useState('')
    const [phone, setPhone] = useState('')
    const [email, setEmail] = useState('')
    const [address, setAddress] = useState('')
    const [userImage, setUserImage] = useState('')
    const [loading, setLoading] = useState(false)
    const token = window.localStorage.getItem('token')
    const [showChanPass, setShowChanPass] = useState(false);
    const [oldPass, setOldPass] = useState('');
    const [newPass, setNewPass] = useState('');
    const [confirmNewPass, setConfirmNewPass] = useState('')
    const [allStatisticsCount, setAllStatisticsCount] = useState({})
    const handleCloseChangPass = () => {
        setShowChanPass(false)
    }
    const handleShowChangPass = () => {
        setShowChanPass(true)
        setOldPass('')
        setNewPass('')
        setConfirmNewPass('')
    }

    //####################################################

    useEffect(() => {
        getUserProfile()
        if (!token) {
            Navigate('/')
        }
    }, [])

    useEffect(() => {
        let headers = {
            Authorization: `Bearer ${window.localStorage.getItem('token')}`,
            "Content-Type": "application/json"
        }
        axios.get(proxy + '/api/Users/GetAllStatisticsCount', { headers }).then(res => {
            setAllStatisticsCount(res.data)
        })
    }, [])
    //####################################################

    const getUserProfile = () => {
        setLoadState(true)
        let headers = {
            Authorization: `Bearer ${window.localStorage.getItem('token')}`,
            "Content-Type": "application/json"
        }
        axios.get(proxy + '/api/Account/UserProfile', { headers }).then(res => {
            setFName(res.data.firstName)
            setLName(res.data.lastName)
            setPhone(res.data.phoneNumber)
            setEmail(res.data.email)
            setAddress(res.data.address)
            setUserImage(res.data.userImage)
            setFullName(res.data.fullName)
            setLoadState(false)
        }).catch(err => {
            setLoadState(false)
        })
    }

    //####################################################

    const updateUserProfile = () => {
        setLoading(true)
        let headers = {
            Authorization: `Bearer ${window.localStorage.getItem('token')}`,
            "Content-Type": "application/json"
        }
        axios.put(proxy + '/api/Account/UpdateUserProfile', {
            firstName: fName,
            lastName: lName,
            fullName: `${lName}${lName}`,
            phoneNumber: phone,
            address: address,
            userImage: imagePase64 === '' ? userImage : imagePase64,
            email: email
        }, { headers }).then(res => {
            setLoading(false)
            getUserProfile()
            getUserImageNav()
            toast.success(res.data.messageEn)
        }).catch(err => {
            setLoading(false)
        })
    }

    //####################################################

    const changePass = () => {
        setLoading(true)
        if (oldPass === '') {
            toast.error('Please enter the old password')
            setLoading(false)
        } else if (newPass === '') {
            toast.error('Please enter the new password')
            setLoading(false)
        } else if (newPass !== confirmNewPass) {
            toast.error('Confirm password does not match the password')
            setLoading(false)
        } else {
            let headers = {
                Authorization: `Bearer ${window.localStorage.getItem('token')}`,
                "Content-Type": "application/json"
            }
            axios.put(proxy + '/api/Account/ChangePassword', {
                oldPassword: oldPass,
                password: newPass
            }, { headers }).then(res => {
                setLoading(false)
                toast.success(res.data.messageEn)
                handleCloseChangPass()
                getUserProfile()
                getUserImageNav()
            }).catch(err => {
                setLoading(false)
                toast.error(err.response.data.messageEn)
            })
        }
    }

    //####################################################

    const [imagePase64, setImagePase64] = useState('')

    const image_to_base64 = async (file) => {
        let result_base64 = await new Promise((resolve) => {
            let fileReader = new FileReader();
            fileReader.onload = (e) => resolve(fileReader.result);
            fileReader.onerror = (error) => {
                alert('error')
            };
            fileReader.readAsDataURL(file);
        })
        return result_base64;
    }

    const reduce_image_file_size = async (base64Str, MAX_WIDTH = 300, MAX_HEIGHT = 300) => {
        let resized_base64 = await new Promise((resolve) => {
            let img = new Image()
            img.src = base64Str
            img.onload = () => {
                let canvas = document.createElement('canvas')
                let width = img.width
                let height = img.height
                if (width > height) {
                    if (width > MAX_WIDTH) {
                        height *= MAX_WIDTH / width
                        width = MAX_WIDTH
                    }
                } else {
                    if (height > MAX_HEIGHT) {
                        width *= MAX_HEIGHT / height
                        height = MAX_HEIGHT
                    }
                }
                canvas.width = width
                canvas.height = height
                let ctx = canvas.getContext('2d')
                ctx.drawImage(img, 0, 0, width, height)
                resolve(canvas.toDataURL())
            }
        })
        return resized_base64
    }
    const calc_image_size = (image) => {
        let y = 1;
        if (image.endsWith('==')) {
            y = 2
        }
        const x_size = (image.length * (3 / 4)) - y;
        return Math.round(x_size / 1024)
    }
    const onChangePase64 = async (e) => {
        const file = document.getElementById('file');
        const res = await image_to_base64(file.files[0])
        if (res) {
            // document.getElementById('old').src = res;
            // const olds = calc_image_size(res)
            // console.log('old size => ', olds, 'KB')
            const resize = await reduce_image_file_size(res)
            const news = calc_image_size(resize)
            console.log('news size => ', news, 'KB')
            setImagePase64(resize)
            // document.getElementById('new').src = resize
        } else {
            console.log('error')
        }
    }

    //############################################

    return (
        <>
            <Modal show={showChanPass} onHide={handleCloseChangPass} className='modaleProfile'>
                <Modal.Header closeButton>
                    <Modal.Title>Change Password</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <Form.Group className="mb-3 col-12">
                            <Form.Label>Old Password</Form.Label>
                            <Form.Control type="password" placeholder="Old Password" value={oldPass} onChange={(e) => setOldPass(e.target.value)} />
                        </Form.Group>
                        <Form.Group className="mb-3 col-12">
                            <Form.Label>New Password</Form.Label>
                            <Form.Control type="password" placeholder="New Password" value={newPass} onChange={(e) => setNewPass(e.target.value)} />
                        </Form.Group>
                        <Form.Group className="mb-3 col-12">
                            <Form.Label>Confirm the new password</Form.Label>
                            <Form.Control type="password" placeholder="New Password" value={confirmNewPass} onChange={(e) => setConfirmNewPass(e.target.value)} />
                        </Form.Group>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {
                        loading ? <button className="btn btn-primary" type="button" disabled>
                            <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                            Loading...
                        </button> : <Button variant="primary" disabled={loading} onClick={() => changePass()} >
                            Save Changes
                        </Button>
                    }
                </Modal.Footer>
            </Modal>
            {
                LoadState ? <Loading /> : <div className="profile">
                    <div className="row">
                        <div className="col-12 col-md-8">
                            <div className="formUser">
                                <div>
                                    <img src={userImage} />
                                    <h6>  {fullName} </h6>
                                </div>
                                <div className="row">
                                    <Form.Group className="mb-3 col-12 col-md-4">
                                        <Form.Label>First Name *</Form.Label>
                                        <Form.Control type="Text" placeholder="First Name" value={fName} onChange={(e) => setFName(e.target.value)} />
                                    </Form.Group>
                                    <Form.Group className="mb-3 col-12 col-md-4">
                                        <Form.Label>Last Name *</Form.Label>
                                        <Form.Control type="Text" placeholder="Last Name" value={lName} onChange={(e) => setLName(e.target.value)} />
                                    </Form.Group>
                                    <Form.Group className="mb-3 col-12 col-md-4">
                                        <Form.Label>Phone Number *</Form.Label>
                                        <Form.Control type="Text" placeholder="Phone Number" value={phone} onChange={(e) => setPhone(e.target.value)} />
                                    </Form.Group>
                                    <Form.Group className="mb-3 col-12 col-md-7">
                                        <Form.Label>Email *</Form.Label>
                                        <Form.Control type="Text" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
                                    </Form.Group>
                                    <Form.Group className="mb-3 col-12 col-md-5">
                                        <Form.Label>Address *</Form.Label>
                                        <Form.Control type="Text" placeholder="Address" value={address} onChange={(e) => setAddress(e.target.value)} />
                                    </Form.Group>
                                    <Form.Group className="mb-3 col-12">
                                        <Form.Group className="mb-3">
                                            <Form.Label>Choose A Photo</Form.Label>
                                            <Form.Control id='file' accept='.jpg , .png , jpeg' type="file" onChange={onChangePase64} />
                                        </Form.Group>
                                    </Form.Group>
                                    {
                                        loading ? <button className="btn btn-primary" type="button" disabled>
                                            <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                                            Loading...
                                        </button> : <>
                                            <button onClick={() => updateUserProfile()} disabled={loading}> Save Changes </button>
                                            <button onClick={() => handleShowChangPass()}> Change Password </button>
                                        </>
                                    }

                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className="dataProfile">
                                <div className="dataProfilHead">
                                    <h6>Welcome {fullName} </h6>
                                </div>
                                <div className="dataProfileImage">
                                    <img src={userImage} />
                                    <h6> {email} </h6>
                                    <h6> {phone} </h6>
                                    <h6> {address} </h6>
                                </div>
                                <div className="dataProfileFoot">
                                    <p> drivers  <span>{allStatisticsCount.driversCount}</span> </p>
                                    <p> clients  <span>{allStatisticsCount.clientsCount}</span> </p>
                                    <p> orders  <span>{allStatisticsCount.ordersCount}</span> </p>
                                    <p> prices <span>{allStatisticsCount.pricesCount}</span> </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
}

export default Profile;