import { TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import axios from "axios";
import dayjs from "dayjs";
import { useContext, useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { Button, Form, Modal, Stack } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import { DashUserContext } from "../context";
import Loading from "./Loading";

const Driver = ({ idLoad, loading, LoadState, setLoadState, active, activeDriverAttat }) => {
    const { proxy } = useContext(DashUserContext)
    const [status, setStatus] = useState('Pending')
    const [driverAttatchment, setDriverAttatchment] = useState([])
    const [idAttat, setIdAttat] = useState('')
    const navigate = useNavigate()
    const token = window.localStorage.getItem('token')
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = (id, status) => {
        setShow(true)
        setIdAttat(id)
        setStatus(status)
    }

    //############################################

    useEffect(() => {
        getAttatchment()
        getDriverStats()
        getPriceDriver()
        if (!token) {
            navigate('/')
        }
    }, [active])

    //############################################

    const getAttatchment = () => {
        setLoadState(true)
        const id = window.location.hash.split('/')[2]
        let headers = {
            Authorization: `Bearer ${window.localStorage.getItem('token')}`,
            "Content-Type": "application/json"
        }
        axios.get(`${proxy}/api/Users/GetDriverAttatchments/${id}`, { headers }).then(res => {
            setDriverAttatchment(res.data)
            setLoadState(false)
        }).catch(err => {
            console.log(err)
            setLoadState(false)
        })
    }

    //############################################

    const month = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec']
    const [orderDriver, setOrderDiver] = useState({
        series: [{
            name: 'Drivers',
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        }],
        options: {
            chart: {
                height: 350,
                type: 'area'
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: 'smooth'
            },
            xaxis: {
                categories: month
            },
        },
    })

    const getDriverStats = () => {
        const id = window.location.hash.split('/')[2]
        let headers = {
            Authorization: `Bearer ${window.localStorage.getItem('token')}`,
            "Content-Type": "application/json"
        }
        axios.get(`${proxy}/api/Users/GetOrdersCountStatisticsForSpecificDriver/${id}`, { headers }).then(res => {
            setOrderDiver({
                series: [{
                    name: 'Drivers',
                    data: res.data
                }],
                options: {
                    chart: {
                        height: 350,
                        type: 'area'
                    },
                    dataLabels: {
                        enabled: true
                    },
                    stroke: {
                        curve: 'smooth'
                    },
                    xaxis: {
                        categories: month
                    },
                },
            })
        }).catch(err => {
            console.log(err)
        })
    }

    const [orderYear, setOrderYear] = useState(dayjs(new Date()))
    const GetOrderYear = (value) => {
        const id = window.location.hash.split('/')[2]
        setOrderYear(value)
        let headers = {
            Authorization: `Bearer ${window.localStorage.getItem('token')}`,
            "Content-Type": "application/json"
        }
        axios.get(`${proxy}/api/Users/GetOrdersCountStatisticsForSpecificDriver/${id}/${value.$y}`, { headers }).then(res => {
            setOrderDiver({
                series: [{
                    name: 'Drivers',
                    data: res.data
                }],
                options: {
                    chart: {
                        height: 350,
                        type: 'area'
                    },
                    dataLabels: {
                        enabled: true
                    },
                    stroke: {
                        curve: 'smooth'
                    },
                    xaxis: {
                        categories: month
                    },
                },
            })
        }).catch(err => {
        })
    }

    //############################################

    const [priceDriver, setPriceDriver] = useState({
        series: [{
            name: 'Drivers',
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        }],
        options: {
            chart: {
                height: 350,
                type: 'bar'
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: 'smooth'
            },
            xaxis: {
                categories: month
            },
        },
    })

    const getPriceDriver = () => {
        const id = window.location.hash.split('/')[2]
        let headers = {
            Authorization: `Bearer ${window.localStorage.getItem('token')}`,
            "Content-Type": "application/json"
        }
        axios.get(`${proxy}/api/Users/GetPricesStatisticsForSpecificDriver/${id}`, { headers }).then(res => {
            setPriceDriver({
                series: [{
                    name: 'Drivers',
                    data: res.data
                }],
                options: {
                    chart: {
                        height: 350,
                        type: 'bar'
                    },
                    dataLabels: {
                        enabled: true
                    },
                    stroke: {
                        curve: 'smooth'
                    },
                    xaxis: {
                        categories: month
                    },
                },
            })
        }).catch(err => {
            console.log(err)
        })
    }

    const [priceYear, setPriceYear] = useState(dayjs(new Date()))
    const GetPriceYear = (value) => {
        const id = window.location.hash.split('/')[2]
        setPriceYear(value)
        let headers = {
            Authorization: `Bearer ${window.localStorage.getItem('token')}`,
            "Content-Type": "application/json"
        }
        axios.get(`${proxy}/api/Users/GetPricesStatisticsForSpecificDriver/${id}/${value.$y}`, { headers }).then(res => {
            setPriceDriver({
                series: [{
                    name: 'Drivers',
                    data: res.data
                }],
                options: {
                    chart: {
                        height: 350,
                        type: 'bar'
                    },
                    dataLabels: {
                        enabled: true
                    },
                    stroke: {
                        curve: 'smooth'
                    },
                    xaxis: {
                        categories: month
                    },
                },
            })
        }).catch(err => {
        })
    }

    //############################################

    const columns = [
        {
            name: 'Content',
            selector: row => <img src={`data:image/jpg;base64,${row.content}`} width='50' height='50' />
        },
        {
            name: 'Status',
            selector: row => row.status
        },
        {
            name: 'SatusAr',
            selector: row => <div className="Ar"> {row.statusAr} </div>
        },
        {
            name: 'action',
            cell: row => <>
                <button className="btn btn-primary" onClick={() => handleShow(row.id, row.status)} >  Change Status </button>
            </>
        }
    ]

    //#############################################

    return (
        <>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Status</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className="mb-3">
                        <Form.Select className="form-control" value={status} onChange={(e) => setStatus(e.target.value)} >
                            <option value='Pending' disabled>Pending</option>
                            <option value='Accepted'>Accepted</option>
                            <option value='Refused'>Refused</option>
                        </Form.Select>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    {
                        loading ? <button className="btn btn-primary" type="button" disabled>
                            <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                            Loading...
                        </button> : <Button variant="primary" disabled={loading} onClick={() => activeDriverAttat(idAttat, status) || handleClose()}>
                            Save Changes
                        </Button>
                    }
                </Modal.Footer>
            </Modal>
            {
                LoadState ? <Loading /> :
                    <>
                        <div className="row">
                            <div className="col-12 col-lg-6 mb-3">
                                <h4 style={{ color: '#00796b', boxShadow: '1px 1px 8px -6px #1a6bb4', padding: '5px', borderRadius: '5px' }}> Orders </h4>
                                <div id="chart">
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <Stack spacing={3}>
                                            <DatePicker
                                                views={['year']}
                                                // label="Drivers"
                                                value={orderYear}
                                                onChange={(newValue) => {
                                                    GetOrderYear(newValue)
                                                }}
                                                renderInput={(params) => <TextField {...params} helperText={null} />}
                                            />
                                        </Stack>
                                    </LocalizationProvider>
                                    <ReactApexChart options={orderDriver.options} series={orderDriver.series} type="area" height={350} />
                                </div>
                            </div>
                            <div className="col-12 col-lg-6 mb-3">
                                <h4 style={{ color: '#00796b', boxShadow: '1px 1px 8px -6px #1a6bb4', padding: '5px', borderRadius: '5px' }}> Price </h4>
                                <div id="chart">
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <Stack spacing={3}>
                                            <DatePicker
                                                views={['year']}
                                                // label="Drivers"
                                                value={priceYear}
                                                onChange={(newValue) => {
                                                    GetPriceYear(newValue)
                                                }}
                                                renderInput={(params) => <TextField {...params} helperText={null} />}
                                            />
                                        </Stack>
                                    </LocalizationProvider>
                                    <ReactApexChart options={priceDriver.options} series={priceDriver.series} type="bar" height={350} />
                                </div>
                            </div>
                        </div>
                        <DataTable
                            columns={columns}
                            data={driverAttatchment}
                            pagination
                            title='Driver Attatchment'
                            fixedHeader
                            // selectableRows
                            // onSelectedRowsChange={(item) => console.table(item.selectedRows)}
                            selectableRowsHighlight
                            highlightOnHover
                        />
                    </>
            }
        </>
    );
}

export default Driver;