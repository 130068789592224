import axios from "axios";
import Modal from 'react-bootstrap/Modal';
import { useContext, useState } from "react";
import { useEffect } from "react";
import Button from 'react-bootstrap/Button';
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import Navbar from "../components/Navbar";
import Form from 'react-bootstrap/Form';
import toast from "react-hot-toast";
import Loading from "./Loading";
import { DashUserContext } from "../context";

const Roles = ({ idLoad, setIdLoad, LoadState, setLoadState }) => {
    const { proxy } = useContext(DashUserContext)
    const [dataRoles, setDataRoles] = useState([])
    const [filterDataRoles, setFilterDataRoles] = useState([])
    const [searchRoles, setSearchRoles] = useState('')
    const [showAdd, setShowAdd] = useState(false);
    const [name, setName] = useState('')
    const [roleNameAr, setRoleNameAr] = useState('')
    const [roleId, setRoleId] = useState('')
    const [loading, setLoading] = useState(false)
    const [showEdit, setShowEdit] = useState(false)
    const navigate = useNavigate()
    const token = window.localStorage.getItem('token')
    const handleCloseAdd = () => setShowAdd(false);
    const handleCloseEdit = () => setShowEdit(false);
    const handleShowAdd = () => {
        setShowAdd(true)
        setName('')
        setRoleNameAr('')
    }
    const handleShowEdit = (itemCar) => {
        setShowEdit(true)
        console.log(itemCar)
        setName(itemCar.name)
        setRoleNameAr(itemCar.roleNameAr)
        setRoleId(itemCar.id)
    }

    //############################################

    useEffect(() => {
        getAllRole()
        if (!token) {
            navigate('/')
        }
    }, [])

    useEffect(() => {
        const result = dataRoles.filter(item => {
            return item.name.toLowerCase().match(searchRoles.toLowerCase())
        })
        setFilterDataRoles(result)
    }, [searchRoles])

    //#############################################

    const getAllRole = () => {
        setLoadState(true)
        let headers = {
            Authorization: `Bearer ${window.localStorage.getItem('token')}`,
            "Content-Type": "application/json"
        }
        axios.get(proxy + '/api/Role/GetAllRoles', { headers }).then(res => {
            setDataRoles(res.data)
            setFilterDataRoles(res.data)
            setLoadState(false)
        }).catch(err => {
            setLoadState(false)
        })
    }

    //############################################

    const addRole = () => {
        setLoading(true)
        if (name === '') {
            toast.error('Please Tnter The Role Name')
            setLoading(false)
        } else if (roleNameAr === '') {
            toast.error('Please Tnter The Role Name Ar')
            setLoading(false)
        } else {
            let headers = {
                Authorization: `Bearer ${window.localStorage.getItem('token')}`,
                "Content-Type": "application/json"
            }
            axios.post(proxy + '/api/Role/AddRole', { name: name, rolAName: roleNameAr }, { headers }).then(res => {
                getAllRole()
                setLoading(false)
                handleCloseAdd()
                toast.success(res.data.messageEn)
            }).catch(err => {
                setLoading(false)
            })
        }

    }

    //############################################

    const deleteRole = (id) => {
        setLoading(true)
        setIdLoad(id)
        let headers = {
            Authorization: `Bearer ${window.localStorage.getItem('token')}`,
            "Content-Type": "application/json"
        }
        axios.delete(`${proxy}/api/Role/DeleteRole/${id}`, { headers }).then(res => {
            getAllRole()
            setLoading(false)
            setIdLoad('')
            toast.success(res.data.messageEn)
        }).catch(err => {
            setLoading(false)
            setIdLoad('')
            toast.error(err.response.data.messageEn)
        })
    }

    //############################################

    const editRole = () => {
        setLoading(true)
        let headers = {
            Authorization: `Bearer ${window.localStorage.getItem('token')}`,
            "Content-Type": "application/json"
        }
        axios.put(`${proxy}/api/Role/EditRole/${roleId}`, { name: name, rolAName: roleNameAr }, { headers }).then(res => {
            handleCloseEdit()
            getAllRole()
            setLoading(false)
            toast.success(res.data.messageEn)
        }).catch(err => {
            setLoading(false)
            toast.error(err.response.data.messageEn)
        })
    }

    //############################################

    const columns = [
        {
            name: 'Name',
            selector: row => row.name
        },
        {
            name: 'roleNameAr',
            selector: row => row.roleNameAr
        },
        {
            name: 'action',
            cell: row => <>
                {
                    row.id === idLoad ? <button className="btn btn-primary" type="button" disabled>
                        <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                        Loading...
                    </button> : <button className="btn btn-primary" onClick={() => deleteRole(row.id)} disabled={row.name === 'SuperAdmin'} >Delete</button>
                }
                <button className="btn btn-primary" onClick={() => handleShowEdit(row)} disabled={row.name === 'SuperAdmin'}>Edit</button>
            </>
        }
    ]

    //#############################################

    return (
        <>
            <Modal show={showAdd} onHide={handleCloseAdd}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Role</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className="mb-3">
                        <Form.Label>Role Name *</Form.Label>
                        <Form.Control type="Text" placeholder="Role Name" value={name} onChange={(e) => setName(e.target.value)} />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Role NameAr *</Form.Label>
                        <Form.Control type="Text" placeholder="Role NameAr" value={roleNameAr} onChange={(e) => setRoleNameAr(e.target.value)} />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    {
                        loading ? <button className="btn btn-primary" type="button" disabled>
                            <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                            Loading...
                        </button> : <Button variant="primary" onClick={() => addRole()} >
                            Add Role
                        </Button>
                    }
                </Modal.Footer>
            </Modal>
            <Modal show={showEdit} onHide={handleCloseEdit}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Role</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className="mb-3">
                        <Form.Label>Role Name</Form.Label>
                        <Form.Control type="Text" placeholder="Role Name" value={name} onChange={(e) => setName(e.target.value)} />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Role NameAr</Form.Label>
                        <Form.Control type="Text" placeholder="Role NameAr" value={roleNameAr} onChange={(e) => setRoleNameAr(e.target.value)} />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    {
                        loading ? <button className="btn btn-primary" type="button" disabled>
                            <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                            Loading...
                        </button> : <Button variant="primary" onClick={() => editRole()} disabled={loading}>
                            Save Changes
                        </Button>
                    }
                </Modal.Footer>
            </Modal>
            <Navbar />
            <Button className="btn btn-primary" onClick={() => handleShowAdd()} style={{ margin: '5px 0 16px' }}>Add Role</Button>
            {
                LoadState ? <Loading /> : <DataTable
                    columns={columns}
                    data={filterDataRoles}
                    pagination
                    title='Roles List'
                    fixedHeader
                    // selectableRows
                    // onSelectedRowsChange={(item) => console.table(item.selectedRows)}
                    selectableRowsHighlight
                    highlightOnHover
                    subHeader
                    subHeaderComponent={<input
                        type='text'
                        placeholder="Search"
                        className="form-control"
                        value={searchRoles}
                        onChange={(e) => setSearchRoles(e.target.value)}
                    />}
                />
            }
        </>
    );
}

export default Roles;