import { TextField } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import axios from 'axios';
import dayjs from 'dayjs';
import { useContext, useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { Stack } from 'react-bootstrap';
import { DashUserContext } from '../context';

const DriverChart = ({ setLoadState }) => {
    const month = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec']
    const { proxy } = useContext(DashUserContext)
    useEffect(() => {
        GetSriversStatistics()
    }, [])

    //#########################################################

    const [driversChart, setDriversChart] = useState({
        series: [{
            name: 'Drivers',
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        }, {
            name: 'Clients',
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        }],
        options: {
            chart: {
                height: 350,
                type: 'area'
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: 'smooth'
            },
            xaxis: {
                categories: month
            },
        },
    })

    const GetSriversStatistics = () => {
        setLoadState(true)
        let headers = {
            Authorization: `Bearer ${window.localStorage.getItem('token')}`,
            "Content-Type": "application/json"
        }
        axios.get(proxy + '/api/Users/GetDriversStatistics', { headers }).then(res => {
            setDriversChart({
                series: [{
                    name: 'Drivers',
                    data: res.data
                }],
                options: {
                    chart: {
                        height: 350,
                        type: 'area'
                    },
                    dataLabels: {
                        enabled: true
                    },
                    stroke: {
                        curve: 'smooth'
                    },
                    xaxis: {
                        categories: month
                    },
                },
            })
            setLoadState(false)
        }).catch(err => {
            setLoadState(false)
        })
    }

    //#########################################################

    const [driversYear, setDriversYear] = useState(dayjs(new Date()))

    const GetDriverStatisticsYear = (value) => {
        setDriversYear(value)
        let headers = {
            Authorization: `Bearer ${window.localStorage.getItem('token')}`,
            "Content-Type": "application/json"
        }
        axios.get(proxy + '/api/Users/GetDriversStatistics/' + value.$y, { headers }).then(res => {
            setDriversChart({
                series: [{
                    name: 'Clients',
                    data: res.data
                }],
                options: {
                    chart: {
                        height: 350,
                        type: 'area'
                    },
                    dataLabels: {
                        enabled: true
                    },
                    stroke: {
                        curve: 'smooth'
                    },
                    xaxis: {
                        categories: month
                    },
                },
            })
        }).catch(err => {
        })
    }

    return (
        <div id="chart" className='chartdriver'>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Stack spacing={3}>
                    <DatePicker
                        views={['year']}
                        // label="Year"
                        value={driversYear}
                        onChange={(newValue) => {
                            GetDriverStatisticsYear(newValue)
                        }}
                        renderInput={(params) => <TextField {...params} helperText={null} />}
                    />
                </Stack>
            </LocalizationProvider>
            <ReactApexChart options={driversChart.options} series={driversChart.series} type="area" height={350} />
        </div>
    );
}

export default DriverChart;