import Button from 'react-bootstrap/Button';
import Navbar from "../components/Navbar";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Loading from "./Loading";
import { useNavigate } from "react-router-dom";
import toast from 'react-hot-toast';
import { DashUserContext } from '../context';

const Document = ({ idLoad, setIdLoad, LoadState, setLoadState }) => {
    const { proxy } = useContext(DashUserContext)
    const [dataOffer, setDataOffer] = useState([])
    const [showAdd, setShowAdd] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [name, setName] = useState('')
    const [nameAr, setNameAr] = useState('')
    const [description, setDescription] = useState('')
    const [descriptionAr, setDescriptionAr] = useState('')
    const [documentId, setDocumentId] = useState('')
    const [isRequired, setIsRequired] = useState(true)
    const [loading, setLoading] = useState(false)
    const handleCloseAdd = () => setShowAdd(false);
    const handleShowAdd = () => {
        setShowAdd(true)
        setName('')
        setNameAr('')
        setDescription('')
        setDescriptionAr('')
        setIsRequired(true)
        setDocumentId('')
    }
    const handleCloseEdit = () => setShowEdit(false);
    const handleShowEdit = (item) => {
        setShowEdit(true)
        setName(item.name)
        setNameAr(item.nameAr)
        setDescription(item.description)
        setDescriptionAr(item.descriptionAr)
        setIsRequired(item.isRequired)
        setDocumentId(item.id)
        console.log(item)
    }
    const token = window.localStorage.getItem('token')
    const navigate = useNavigate()

    //############################################

    useEffect(() => {
        if (!token) {
            navigate('/')
        }
    }, [])

    useEffect(() => {
        getAllDocuments()
    }, [])

    //###############################################

    const getAllDocuments = () => {
        setLoadState(true)
        let headers = {
            Authorization: `Bearer ${window.localStorage.getItem('token')}`,
            "Content-Type": "application/json"
        }
        axios.get(proxy + '/api/Document/GetAllDocuments', { headers }).then(res => {
            setDataOffer(res.data)
            setLoadState(false)
        }).catch(err => {
            setLoadState(false)
        })
    }

    //############################################

    const editDocument = () => {
        setLoading(true)
        let headers = {
            Authorization: `Bearer ${window.localStorage.getItem('token')}`,
            "Content-Type": "application/json"
        }
        axios.put(`${proxy}/api/Document/EditDocument/${documentId}`, { name: name, nameAr: nameAr, description: description, descriptionAr: descriptionAr, isRequired: isRequired }, { headers }).then(res => {
            handleCloseEdit()
            getAllDocuments()
            setLoading(false)
            toast.success(res.data.messageEn)
        }).catch(err => {
            setLoading(false)
            toast.error(err.response.data.messageEn)
        })
    }

    //###############################################

    const addDocument = () => {
        setLoading(true)
        let headers = {
            Authorization: `Bearer ${window.localStorage.getItem('token')}`,
            "Content-Type": "application/json"
        }
        axios.post(proxy + '/api/Document/AddDocument', { name: name, nameAr: nameAr, description: description, descriptionAr: descriptionAr, isRequired: isRequired }, { headers }).then(res => {
            console.log('yes')
            getAllDocuments()
            handleCloseAdd()
            setLoading(false)
            toast.success(res.data.messageEn)
        }).catch(err => {
            setLoading(false)
        })
    }

    //###############################################

    const deleteDocument = (id) => {
        setLoading(true)
        setIdLoad(id)
        let headers = {
            Authorization: `Bearer ${window.localStorage.getItem('token')}`,
            "Content-Type": "application/json"
        }
        axios.delete(`${proxy}/api/Document/DeleteDocument/${id}`, { headers }).then(res => {
            getAllDocuments()
            setLoading(false)
            setIdLoad('')
            toast.success(res.data.messageEn)
        }).catch(err => {
            setLoading(false)
            toast.error(err.response.data.messageEn)
            setIdLoad('')
        })
    }

    //################################################

    return (
        <div className="cars">
            <Modal show={showAdd} onHide={handleCloseAdd}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Document</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className="mb-3">
                        <Form.Label>Name</Form.Label>
                        <Form.Control type="Text" placeholder="Name" value={name} onChange={(e) => setName(e.target.value)} />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>NameAr</Form.Label>
                        <Form.Control className="form-control" type="Text" placeholder="NameAr" value={nameAr} onChange={(e) => setNameAr(e.target.value)} />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Description</Form.Label>
                        <Form.Control className="form-control" type="Text" placeholder="Description" value={description} onChange={(e) => setDescription(e.target.value)} />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>DescriptionAr</Form.Label>
                        <Form.Control className="form-control" type="Text" placeholder="DescriptionAr" value={descriptionAr} onChange={(e) => setDescriptionAr(e.target.value)} />
                    </Form.Group>
                    {/* <Form.Group className="mb-3">
                        <Form.Label>isRequired</Form.Label>
                        <Form.Select className="form-control" value={isRequired} onChange={(e) => setIsRequired(e.target.value)} >
                            <option value={true}>Active</option>
                            <option value={false}>Un Active</option>
                        </Form.Select>
                    </Form.Group> */}
                    <Form.Label>isRequired</Form.Label>
                    <div className="input-group mb-3">
                        <div className="input-group-text">
                            <input defaultChecked={isRequired} onChange={(e) => setIsRequired(!isRequired)} className="form-check-input mt-0" type="checkbox" value="" aria-label="Checkbox for following text input" />
                        </div>
                        <input type="text" className="form-control" aria-label="Text input with checkbox" disabled placeholder={isRequired ? 'Active' : 'Un Active'} />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {
                        loading ? <button className="btn btn-primary" type="button" disabled>
                            <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                            Loading...
                        </button> : <Button variant="primary" onClick={() => addDocument()} disabled={loading} >
                            Add Document
                        </Button>
                    }
                </Modal.Footer>
            </Modal>
            <Modal show={showEdit} onHide={handleCloseEdit}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Document</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className="mb-3">
                        <Form.Label>Name</Form.Label>
                        <Form.Control type="Text" placeholder="Name" value={name} onChange={(e) => setName(e.target.value)} />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>NameAr</Form.Label>
                        <Form.Control className="form-control Ar" type="Text" placeholder="NameAr" value={nameAr} onChange={(e) => setNameAr(e.target.value)} />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Description</Form.Label>
                        <Form.Control className="form-control" type="Text" placeholder="Description" value={description} onChange={(e) => setDescription(e.target.value)} />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>DescriptionAr</Form.Label>
                        <Form.Control className="form-control Ar" type="Text" placeholder="DescriptionAr" value={descriptionAr} onChange={(e) => setDescriptionAr(e.target.value)} />
                    </Form.Group>
                    {/* <Form.Group className="mb-3">
                        <Form.Label>isRequired</Form.Label>
                        <Form.Select className="form-control" value={isRequired} onChange={(e) => setIsRequired(e.target.value)} >
                            <option value={true}>Active</option>
                            <option value={false}>Un Active</option>
                        </Form.Select>
                    </Form.Group> */}
                    <Form.Label>isRequired</Form.Label>
                    <div className="input-group mb-3">
                        <div className="input-group-text">
                            <input defaultChecked={isRequired} onChange={(e) => setIsRequired(!isRequired)} className="form-check-input mt-0" type="checkbox" value="" aria-label="Checkbox for following text input" />
                        </div>
                        <input type="text" className="form-control" aria-label="Text input with checkbox" disabled placeholder={isRequired ? 'Active' : 'Un Active'} />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {
                        loading ? <button className="btn btn-primary" type="button" disabled>
                            <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                            Loading...
                        </button> : <Button variant="primary" disabled={loading} onClick={() => editDocument()} >
                            Save Changes
                        </Button>
                    }
                </Modal.Footer>
            </Modal>
            <Navbar />
            <Button className="btn btn-primary" onClick={() => handleShowAdd()} >Add Document</Button>
            {
                LoadState ? <Loading /> : <div className="PagSlider">
                    <div className="row">
                        {
                            dataOffer.length !== 0 ? dataOffer.map((item, index) => {
                                return (
                                    <div className="col-12 col-md-6 col-lg-3" key={index}>
                                        <div className="cartPag">
                                            <h5><i className="fa-solid fa-file"></i></h5>
                                            <h3> {item.name} </h3>
                                            <h3 className="Ar"> {item.nameAr} </h3>
                                            <p> {item.description} </p>
                                            <p className="Ar"> {item.descriptionAr} </p>
                                            <h6> Status : <span> {item.isRequired === true ? 'Active' : 'Un Active'} </span> </h6>
                                            <div className="text-center">
                                                {
                                                    item.id === idLoad ? <button className="btn btn-primary" type="button" disabled>
                                                        <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                                                        Loading...
                                                    </button> : <button className="btn-primary" onClick={() => deleteDocument(item.id)}>Delete</button>
                                                }
                                                <button className="btn-primary" onClick={() => handleShowEdit(item)}>Edit</button>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }) : ''
                        }
                    </div>
                </div>
            }
        </div>
    );
}

export default Document;