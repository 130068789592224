/* eslint-disable jsx-a11y/alt-text */
import Button from 'react-bootstrap/Button';
import Navbar from "../components/Navbar";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import toast from "react-hot-toast";
import Loading from "./Loading";
import { useNavigate } from "react-router-dom";
import { DashUserContext } from '../context';

const Slider = ({ idLoad, setIdLoad, LoadState, setLoadState }) => {
    const { proxy } = useContext(DashUserContext)
    const [dataSlider, setDataSlider] = useState([])
    const [showAdd, setShowAdd] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [title, setTitle] = useState('')
    const [content, setContent] = useState('')
    const [imageSlider, setImageSlider] = useState('')
    const [SliderId, setSliderId] = useState('')
    const [active, setActive] = useState(true)
    const [loading, setLoading] = useState(false)
    const [imageAddSlider, setImageAddSlider] = useState('')
    const handleCloseAdd = () => setShowAdd(false);
    const handleShowAdd = () => {
        setShowAdd(true)
        setTitle('')
        setContent('')
        setImageSlider('')
        setActive(true)
        setSliderId('')
    }
    const handleCloseEdit = () => setShowEdit(false);
    const handleShowEdit = (item) => {
        setShowEdit(true)
        setTitle(item.title)
        setContent(item.content)
        setImageSlider(item.image)
        setActive(item.active)
        setSliderId(item.id)
    }
    const token = window.localStorage.getItem('token')
    const navigate = useNavigate()

    //############################################

    useEffect(() => {
        if (!token) {
            navigate('/')
        }
    }, [])

    useEffect(() => {
        getAllSlider()
    }, [])

    //###############################################

    const getAllSlider = () => {
        setLoadState(true)
        let headers = {
            Authorization: `Bearer ${window.localStorage.getItem('token')}`,
            "Content-Type": "application/json"
        }
        axios.get(proxy + '/api/Slider/GetAllSliders', { headers }).then(res => {
            setDataSlider(res.data)
            setLoadState(false)
        }).catch(err => {
            setLoadState(false)
        })
    }
    //############################################
    console.log(imageAddSlider)
    const editCar = () => {
        setLoading(true)
        let headers = {
            Authorization: `Bearer ${window.localStorage.getItem('token')}`,
            "Content-Type": "application/json"
        }
        let fd = new FormData()
        fd.append("title", title)
        fd.append("content", content)
        fd.append("image", imageAddSlider === '' ? imageSlider : imageAddSlider)
        fd.append("active", active)
        axios.put(`${proxy}/api/Slider/EditSlider/${SliderId}`, fd, { headers }).then(res => {
            handleCloseEdit()
            getAllSlider()
            setLoading(false)
            toast.success(res.data.messageEn)
        }).catch(err => {
            setLoading(false)
            toast.error(err.response.data.messageEn)
        })
    }

    //###############################################

    const addSlider = () => {
        setLoading(true)
        if (title === '') {
            toast.error('Please Tnter The Title')
            setLoading(false)
        } else if (imageAddSlider === '') {
            toast.error('Please Choose A Picture')
            setLoading(false)
        } else {
            let headers = {
                Authorization: `Bearer ${window.localStorage.getItem('token')}`,
                "Content-Type": "application/json"
            }
            let fd = new FormData()
            fd.append("title", title)
            fd.append("content", content)
            fd.append("image", imageAddSlider)
            fd.append("active", active)
            axios.post(proxy + '/api/Slider/AddSlider', fd, { headers }).then(res => {
                getAllSlider()
                handleCloseAdd()
                setLoading(false)
                toast.success(res.data.messageEn)
            }).catch(err => {
                setLoading(false)
                toast.error(err.response.data.messageEn)
            })
        }
    }
    //###############################################

    const deleteSlider = (id) => {
        setLoading(true)
        setIdLoad(id)
        let headers = {
            Authorization: `Bearer ${window.localStorage.getItem('token')}`,
            "Content-Type": "application/json"
        }
        axios.delete(`${proxy}/api/Slider/DeleteSlider/${id}`, { headers }).then(res => {
            getAllSlider()
            setLoading(false)
            setIdLoad('')
            toast.success(res.data.messageEn)
        }).catch(err => {
            setLoading(false)
            setIdLoad('')
            toast.error(err.response.data.messageEn)
        })
    }

    //###############################################

    return (
        <div className="cars">
            <Modal show={showAdd} onHide={handleCloseAdd}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Slider</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form encType='mult/FormDate'>
                        <Form.Group className="mb-3">
                            <Form.Label>Title *</Form.Label>
                            <Form.Control type="Text" placeholder="Title" value={title} onChange={(e) => setTitle(e.target.value)} />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Choose A Photo *</Form.Label>
                            <Form.Control id='file' accept='.jpg , .png , jpeg' type="file" placeholder="Vehicle TypeAr" onChange={(e) => setImageAddSlider(e.target.files[0])} />
                        </Form.Group>
                        <Form.Label>Status</Form.Label>
                        <div className="input-group mb-3">
                            <div className="input-group-text">
                                <input defaultChecked={active} onChange={(e) => setActive(!active)} className="form-check-input mt-0" type="checkbox" value="" aria-label="Checkbox for following text input" />
                            </div>
                            <input type="text" className="form-control" aria-label="Text input with checkbox" disabled placeholder={active ? 'Active' : 'Un Active'} />
                        </div>
                        <Form.Group className="mb-3">
                            <Form.Label>Content</Form.Label>
                            <textarea className="form-control" type="Text" placeholder="Content" value={content} onChange={(e) => setContent(e.target.value)} />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    {
                        loading ? <button className="btn btn-primary" type="button" disabled>
                            <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                            Loading...
                        </button> : <Button variant="primary" onClick={() => addSlider()} disabled={loading} >
                            Add Slider
                        </Button>
                    }

                </Modal.Footer>
            </Modal>
            <Modal show={showEdit} onHide={handleCloseEdit}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Slider</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className="mb-3">
                        <Form.Label>Title</Form.Label>
                        <Form.Control type="Text" placeholder="Title" value={title} onChange={(e) => setTitle(e.target.value)} />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Choose A Photo</Form.Label>
                        <Form.Control id='file' accept='.jpg , .png , jpeg' type="file" placeholder="Vehicle TypeAr" onChange={(e) => setImageAddSlider(e.target.files[0])} />
                    </Form.Group>
                    <Form.Label>Status</Form.Label>
                    <div className="input-group mb-3">
                        <div className="input-group-text">
                            <input defaultChecked={active} onChange={(e) => setActive(!active)} className="form-check-input mt-0" type="checkbox" value="" aria-label="Checkbox for following text input" />
                        </div>
                        <input type="text" className="form-control" aria-label="Text input with checkbox" disabled placeholder={active ? 'Active' : 'Un Active'} />
                    </div>
                    <Form.Group className="mb-3">
                        <Form.Label>Content</Form.Label>
                        <textarea className="form-control" type="Text" placeholder="Content" value={content} onChange={(e) => setContent(e.target.value)} />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    {
                        loading ? <button className="btn btn-primary" type="button" disabled>
                            <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                            Loading...
                        </button> : <Button variant="primary" disabled={loading} onClick={() => editCar()} >
                            Save Changes
                        </Button>
                    }
                </Modal.Footer>
            </Modal>
            <Navbar />
            <Button className="btn btn-primary" onClick={() => handleShowAdd()} >Add Slider</Button>
            {
                LoadState ? <Loading /> : <div className="PagSlider">
                    <div className="row">
                        {
                            dataSlider.length !== 0 ? dataSlider.map((item, index) => {
                                return (
                                    <div className="col-12 col-md-6 col-lg-3" key={index}>
                                        <div className="cartPag">
                                            <img src={`${proxy}/Resources/Sliders/${item.image}`} />
                                            <h4> {item.title} </h4>
                                            <p> {item.content} </p>
                                            <h6> Status : <span> {item.active === true ? 'Active' : 'Un Active'} </span> </h6>
                                            <div className="text-center">
                                                {item.id === idLoad ? <button className="btn btn-primary" type="button" disabled>
                                                    <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                                                    Loading...
                                                </button> : <button className="btn-primary" onClick={() => deleteSlider(item.id)}>Delete</button>}
                                                <button className="btn-primary" onClick={() => handleShowEdit(item)}>Edit</button>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }) : ''
                        }
                    </div>
                </div>
            }
        </div>
    );
}

export default Slider;