import { TextField } from "@mui/material"
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import axios from "axios"
import dayjs from "dayjs"
import { useContext, useEffect, useState } from "react"
import ReactApexChart from "react-apexcharts"
import { Stack } from "react-bootstrap"
import { DashUserContext } from "../context"

const Clinet = () => {
    const month = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec']
    const { proxy } = useContext(DashUserContext)
    useEffect(() => {
        getClientOrder()
        getPriceCLient()
    }, [])

    //####################################################

    const [orderCLient, setOrderCLient] = useState({
        series: [{
            name: 'Drivers',
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        }],
        options: {
            chart: {
                height: 350,
                type: 'area'
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: 'smooth'
            },
            xaxis: {
                categories: month
            },
        },
    })

    const getClientOrder = () => {
        const id = window.location.hash.split('/')[2]
        let headers = {
            Authorization: `Bearer ${window.localStorage.getItem('token')}`,
            "Content-Type": "application/json"
        }
        axios.get(`${proxy}/api/Users/GetOrdersCountStatisticsForSpecificClient/${id}`, { headers }).then(res => {
            setOrderCLient({
                series: [{
                    name: 'Drivers',
                    data: res.data
                }],
                options: {
                    chart: {
                        height: 350,
                        type: 'area'
                    },
                    dataLabels: {
                        enabled: true
                    },
                    stroke: {
                        curve: 'smooth'
                    },
                    xaxis: {
                        categories: month
                    },
                },
            })
        }).catch(err => {
            console.log(err)
        })
    }

    const [orderYear, setOrderYear] = useState(dayjs(new Date()))
    const GetOrderYear = (value) => {
        const id = window.location.hash.split('/')[2]
        setOrderYear(value)
        let headers = {
            Authorization: `Bearer ${window.localStorage.getItem('token')}`,
            "Content-Type": "application/json"
        }
        axios.get(`${proxy}/api/Users/GetOrdersCountStatisticsForSpecificClient/${id}/${value.$y}`, { headers }).then(res => {
            setOrderCLient({
                series: [{
                    name: 'Drivers',
                    data: res.data
                }],
                options: {
                    chart: {
                        height: 350,
                        type: 'area'
                    },
                    dataLabels: {
                        enabled: true
                    },
                    stroke: {
                        curve: 'smooth'
                    },
                    xaxis: {
                        categories: month
                    },
                },
            })
        }).catch(err => {
        })
    }

    //####################################################

    const [priceClient, setPriceClient] = useState({
        series: [{
            name: 'Drivers',
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        }],
        options: {
            chart: {
                height: 350,
                type: 'bar'
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: 'smooth'
            },
            xaxis: {
                categories: month
            },
        },
    })


    const getPriceCLient = () => {
        const id = window.location.hash.split('/')[2]
        let headers = {
            Authorization: `Bearer ${window.localStorage.getItem('token')}`,
            "Content-Type": "application/json"
        }
        axios.get(`${proxy}/api/Users/GetPricesStatisticsForSpecificClient/${id}`, { headers }).then(res => {
            setPriceClient({
                series: [{
                    name: 'Drivers',
                    data: res.data
                }],
                options: {
                    chart: {
                        height: 350,
                        type: 'bar'
                    },
                    dataLabels: {
                        enabled: true
                    },
                    stroke: {
                        curve: 'smooth'
                    },
                    xaxis: {
                        categories: month
                    },
                },
            })
        }).catch(err => {
            console.log(err)
        })
    }

    const [priceYear, setPriceYear] = useState(dayjs(new Date()))
    const GetPriceYear = (value) => {
        const id = window.location.hash.split('/')[2]
        setPriceYear(value)
        let headers = {
            Authorization: `Bearer ${window.localStorage.getItem('token')}`,
            "Content-Type": "application/json"
        }
        axios.get(`${proxy}/api/Users/GetPricesStatisticsForSpecificClient/${id}/${value.$y}`, { headers }).then(res => {
            setPriceClient({
                series: [{
                    name: 'Drivers',
                    data: res.data
                }],
                options: {
                    chart: {
                        height: 350,
                        type: 'bar'
                    },
                    dataLabels: {
                        enabled: true
                    },
                    stroke: {
                        curve: 'smooth'
                    },
                    xaxis: {
                        categories: month
                    },
                },
            })
        }).catch(err => {
        })
    }

    //############################################

    return (
        <div className="row">
            <div className="col-12 col-lg-6 mb-3">
                <h4 style={{ color: '#00796b', boxShadow: '1px 1px 8px -6px #1a6bb4', padding: '5px', borderRadius: '5px' }}> Orders </h4>
                <div id="chart">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Stack spacing={3}>
                            <DatePicker
                                views={['year']}
                                // label="Drivers"
                                value={orderYear}
                                onChange={(newValue) => {
                                    GetOrderYear(newValue)
                                }}
                                renderInput={(params) => <TextField {...params} helperText={null} />}
                            />
                        </Stack>
                    </LocalizationProvider>
                    <ReactApexChart options={orderCLient.options} series={orderCLient.series} type="area" height={350} />
                </div>
            </div>
            <div className="col-12 col-lg-6 mb-3">
                <h4 style={{ color: '#00796b', boxShadow: '1px 1px 8px -6px #1a6bb4', padding: '5px', borderRadius: '5px' }}> Price </h4>
                <div id="chart">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Stack spacing={3}>
                            <DatePicker
                                views={['year']}
                                // label="Drivers"
                                value={priceYear}
                                onChange={(newValue) => {
                                    GetPriceYear(newValue)
                                }}
                                renderInput={(params) => <TextField {...params} helperText={null} />}
                            />
                        </Stack>
                    </LocalizationProvider>
                    <ReactApexChart options={priceClient.options} series={priceClient.series} type="bar" height={350} />
                </div>
            </div>
        </div>
    );
}

export default Clinet;