import axios from "axios";
import { useContext, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { DashUserContext } from "../context";
import Loading from "./Loading";


const Drivers = ({ activation, active, loading, idLoad, LoadState, setLoadState }) => {

    const [dataDriver, setDataDriver] = useState([])
    const [filterDataDriver, setFilterDataDriver] = useState([])
    const [searchUserNameDriver, setSearchUserNameDriver] = useState('')
    const navigate = useNavigate()
    const token = window.localStorage.getItem('token')
    const { proxy } = useContext(DashUserContext)
    //############################################

    useEffect(() => {
        getAllDriver()
        if (!token) {
            navigate('/')
        }
    }, [active])

    useEffect(() => {
        const result = dataDriver.filter(item => {
            return item.fullName.toLowerCase().match(searchUserNameDriver.toLowerCase())
        })
        setFilterDataDriver(result)
    }, [searchUserNameDriver])

    //############################################

    const getAllDriver = () => {
        setLoadState(true)
        let headers = {
            Authorization: `Bearer ${window.localStorage.getItem('token')}`,
            "Content-Type": "application/json"
        }
        axios.get(proxy + '/api/Users/GetAllDrivers', { headers }).then(res => {
            setDataDriver(res.data)
            setFilterDataDriver(res.data)
            setLoadState(false)
        }).catch(err => {
            setLoadState(false)
        })
    }

    //############################################

    const columns = [
        {
            name: 'Driver Name',
            selector: row => row.fullName
        },
        {
            name: 'Email',
            selector: row => row.email
        },
        {
            name: 'Address',
            selector: row => row.address
        },
        {
            name: 'Phone Number',
            selector: row => row.phoneNumber
        },
        {
            name: 'Status',
            selector: row => row.isActive === true ? 'Active' : 'In Active'
        },
        {
            name: 'action',
            cell: row => <>
                {row.id === idLoad ? <button className="btn btn-primary" type="button" disabled>
                    <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                    Loading...
                </button> : <button className="btn btn-primary" onClick={() => activation(row.id)}> {row.isActive === true ? 'In Active' : 'Active'} </button>}
                <Link to={'/Drivers/' + row.id} className="btn btn-primary"> Attatchment </Link>
            </>
        }
    ]

    //############################################

    return (
        <>
            {
                LoadState ? <Loading /> : <DataTable
                    columns={columns}
                    data={filterDataDriver}
                    pagination
                    title='Driver List'
                    fixedHeader
                    // selectableRows
                    // onSelectedRowsChange={(item) => console.table(item.selectedRows)}
                    selectableRowsHighlight
                    highlightOnHover
                    subHeader
                    subHeaderComponent={<input
                        type='text'
                        placeholder="Search"
                        className="form-control"
                        value={searchUserNameDriver}
                        onChange={(e) => setSearchUserNameDriver(e.target.value)}
                    />}
                />
            }
        </>
    );
}

export default Drivers;