/* eslint-disable jsx-a11y/role-supports-aria-props */
import { NavLink } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Collapse from 'react-bootstrap/Collapse';
import { useEffect, useState } from 'react';

const Sidbar = () => {

    const [open, setOpen] = useState(false);
    const location = window.location.hash
    useEffect(() => {
        if (location === '#/Cars' || location === '#/Roles') {
            setOpen(true)
        }
    }, [location])

    //#######################################################

    return (
        <div className="row">
            <div className="col-12 col-sm-3  col-md-3 col-lg-2 sidbar">
                <ul>
                    <li className="nav-item d-none d-sm-block" style={{ marginBottom: '5px', padding: '10px 15px', fontWeight: '600', fontSize: '23px', color: '#fcaf17' }}>
                        <i className="fa-solid fa-gauge" style={{ marginRight: '8px' }}></i>Dashbord
                    </li>
                    <li>
                        <NavLink to='/Home'>
                            <i className="fa-solid fa-house-user"></i>
                            <span className="d-none d-sm-inline">Home</span>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to='/Clients'>
                            <i className="fa-solid fa-user"></i>
                            <span className="d-none d-sm-inline">Clients</span>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to='/Drivers'>
                            <i className="fa-solid fa-id-card"></i>
                            <span className="d-none d-sm-inline">Drivers</span>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to='/Users'>
                            <i className="fa-solid fa-users"></i>
                            <span className="d-none d-sm-inline">Users</span>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to='/Slider'>
                            <i className="fa-solid fa-sliders"></i>
                            <span className="d-none d-sm-inline">Slider</span>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to='/Offer'>
                            <i className="fa-solid fa-sliders"></i>
                            <span className="d-none d-sm-inline">Offer</span>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to='/Document'>
                            <i className="fa-solid fa-file"></i>
                            <span className="d-none d-sm-inline">Document</span>
                        </NavLink>
                    </li>
                    <li className='collapaSeeting'
                        onClick={() => setOpen(!open)}
                        aria-controls="example-collapse-text"
                        aria-expanded={open}>
                        <i className="fa-solid fa-gear"></i>
                        <span className="d-none d-sm-inline">Setting</span>
                    </li>
                    <Collapse in={open}>
                        <div id="example-collapse-text" className='collapsettShow'>
                            <ul>
                                <li>
                                    <NavLink to='/Cars'>
                                        <i className="fa-solid fa-car"></i>
                                        <span className="d-none d-sm-inline">Cars</span>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to='/Roles'>
                                        <i className="fa-solid fa-wand-sparkles"></i>
                                        <span className="d-none d-sm-inline">Roles</span>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to='/TermsAndCondition'>
                                        <i className="fa-solid fa-note-sticky"></i>
                                        <span className="d-none d-sm-inline">Terms And Condition</span>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to='/PrivacyPolicy'>
                                        <i className="fa-regular fa-clipboard"></i>
                                        <span className="d-none d-sm-inline">Privacy Policy</span>
                                    </NavLink>
                                </li>
                            </ul>
                        </div>
                    </Collapse>
                </ul>
            </div>
        </div>
    );
}

export default Sidbar;