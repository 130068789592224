/* eslint-disable jsx-a11y/alt-text */
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import DataTable from "react-data-table-component";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { DashUserContext } from "../context";
import Loading from "./Loading";

const Users = ({ activation, active, idLoad, LoadState, setLoadState }) => {
    const { proxy } = useContext(DashUserContext)
    const [dataRoles, setDataRoles] = useState([])
    const [fName, setFName] = useState('')
    const [lName, setLName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [address, setAddress] = useState('')
    const [userImage, setUserImage] = useState('')
    const [roleId, setRoleId] = useState('Choose the state')
    const [editId, setEditId] = useState('')
    const [activeUser, setActivUser] = useState(true)
    const [password, setPassword] = useState('')
    const [showAdd, setShowAdd] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [dataUsers, setDataUsers] = useState([])
    const [loading, setLoading] = useState(false)
    const [filterDataUsers, setFilterDataUsers] = useState([])
    const [searchUserNameUsers, setSearchUserNameUsers] = useState('')
    const handleCloseAdd = () => setShowAdd(false);
    const handleShowAdd = () => {
        setShowAdd(true)
        setFName('')
        setLName('')
        setEmail('')
        setPhone('')
        setAddress('')
        setUserImage('')
        setRoleId('Choose the state')
        setActivUser(true)
        setPassword('')
    }
    const handleCloseEdit = () => setShowEdit(false);
    const handleShowEdit = (user) => {
        setShowEdit(true)
        console.log(user)
        setFName(user.firstName)
        setLName(user.lastName)
        setEmail(user.email)
        setPhone(user.phoneNumber)
        setAddress(user.address)
        setUserImage(user.userImage)
        setRoleId(user.roleId == null ? 'Choose the state' : user.roleId)
        setActivUser(user.isActive)
        setEditId(user.id)
    }
    const navigate = useNavigate()
    const token = window.localStorage.getItem('token')

    //############################################

    useEffect(() => {
        getAllUsers()
        if (!token) {
            navigate('/')
        }
    }, [active])

    useEffect(() => {
        const result = dataUsers.filter(item => {
            return item.fullName.toLowerCase().match(searchUserNameUsers.toLowerCase())
        })
        setFilterDataUsers(result)
    }, [searchUserNameUsers])
    useEffect(() => {
        getAllRole()
    }, [])

    //############################################

    const getAllRole = () => {
        setLoadState(true)
        let headers = {
            Authorization: `Bearer ${window.localStorage.getItem('token')}`,
            "Content-Type": "application/json"
        }
        axios.get(proxy + '/api/Role/GetAllRoles', { headers }).then(res => {
            setDataRoles(res.data)
            setLoadState(false)
        }).catch(err => {
            setLoadState(false)
        })
    }

    //############################################

    const getAllUsers = () => {
        setLoadState(true)
        let headers = {
            Authorization: `Bearer ${window.localStorage.getItem('token')}`,
            "Content-Type": "application/json"
        }
        axios.get(proxy + '/api/Users/GetAllUsers', { headers }).then(res => {
            setDataUsers(res.data)
            setFilterDataUsers(res.data)
            setLoadState(false)
        }).catch(err => {
            setLoadState(false)
        })
    }

    //###############################################

    const addUser = () => {
        setLoading(true)
        let headers = {
            Authorization: `Bearer ${window.localStorage.getItem('token')}`,
            "Content-Type": "application/json"
        }
        axios.post(proxy + '/api/Users/AddUser', { firstName: fName, lastName: lName, email: email, phoneNumber: phone, isActive: activeUser, password: password, address: address, userImage: imagePase64, roleId: roleId }, { headers }).then(res => {
            getAllUsers()
            handleCloseAdd()
            setLoading(false)
            toast.success(res.data.messageEn)
        }).catch(err => {
            toast.error(err.response.data.messageEn)
            setLoading(false)
        })
    }

    //###############################################

    const editUser = () => {
        setLoading(true)
        let headers = {
            Authorization: `Bearer ${window.localStorage.getItem('token')}`,
            "Content-Type": "application/json"
        }
        axios.post(`${proxy}/api/Users/EditUser/${editId}`, { firstName: fName, lastName: lName, email: email, phoneNumber: phone, isActive: activeUser, password: password, address: address, userImage: imagePase64 === '' ? userImage : imagePase64, roleId: roleId }, { headers }).then(res => {
            getAllUsers()
            handleCloseEdit()
            setLoading(false)
            toast.success(res.data.messageEn)
        }).catch(err => {
            toast.error(err.response.data.messageEn)
            setLoading(false)
        })
    }

    //###############################################

    const columns = [
        {
            name: 'Full Name',
            selector: row => row.fullName
        },
        {
            name: 'Image',
            selector: row => <img src={row.userImage} width='70' height='70' />
        },
        {
            name: 'Email',
            selector: row => row.email
        },
        {
            name: 'Address',
            selector: row => row.address
        },
        {
            name: 'Phone Number',
            selector: row => row.phoneNumber
        },
        {
            name: 'Status',
            selector: row => row.isActive === true ? 'Active' : 'In Active'
        },
        {
            name: 'Role',
            selector: row => row.roleEn
        },
        {
            name: 'action',
            cell: row => <>
                {row.id === idLoad ? <button className="btn btn-primary" type="button" disabled>
                    <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                    Loading...
                </button> : <button className="btn btn-primary" onClick={() => activation(row.id)} disabled={loading}> {row.isActive === true ? 'In Active' : 'Active'} </button>}
                <button className="btn btn-primary" onClick={() => handleShowEdit(row)}> Edit </button>
            </>
        }
    ]

    //############################################

    const [imagePase64, setImagePase64] = useState('')
    const image_to_base64 = async (file) => {
        let result_base64 = await new Promise((resolve) => {
            let fileReader = new FileReader();
            fileReader.onload = (e) => resolve(fileReader.result);
            fileReader.onerror = (error) => {
                alert('error')
            };
            fileReader.readAsDataURL(file);
        })
        return result_base64;
    }

    const reduce_image_file_size = async (base64Str, MAX_WIDTH = 300, MAX_HEIGHT = 300) => {
        let resized_base64 = await new Promise((resolve) => {
            let img = new Image()
            img.src = base64Str
            img.onload = () => {
                let canvas = document.createElement('canvas')
                let width = img.width
                let height = img.height
                if (width > height) {
                    if (width > MAX_WIDTH) {
                        height *= MAX_WIDTH / width
                        width = MAX_WIDTH
                    }
                } else {
                    if (height > MAX_HEIGHT) {
                        width *= MAX_HEIGHT / height
                        height = MAX_HEIGHT
                    }
                }
                canvas.width = width
                canvas.height = height
                let ctx = canvas.getContext('2d')
                ctx.drawImage(img, 0, 0, width, height)
                resolve(canvas.toDataURL())
            }
        })
        return resized_base64
    }
    const calc_image_size = (image) => {
        let y = 1;
        if (image.endsWith('==')) {
            y = 2
        }
        const x_size = (image.length * (3 / 4)) - y;
        return Math.round(x_size / 1024)
    }
    const onChangePase64 = async (e) => {
        const file = document.getElementById('file');
        const res = await image_to_base64(file.files[0])
        if (res) {
            // document.getElementById('old').src = res;
            // const olds = calc_image_size(res)
            // console.log('old size => ', olds, 'KB')
            const resize = await reduce_image_file_size(res)
            const news = calc_image_size(resize)
            console.log('news size => ', news, 'KB')
            setImagePase64(resize)
            // document.getElementById('new').src = resize
        } else {
            console.log('error')
        }
    }

    //#######################################################

    return (
        <div className="user">
            <Modal show={showAdd} onHide={handleCloseAdd} className='modalAddUser'>
                <Modal.Header closeButton>
                    <Modal.Title>Add User</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <Form.Group className="mb-3 col-12 col-md-4">
                            <Form.Label>First Name *</Form.Label>
                            <Form.Control type="Text" placeholder="First Name" value={fName} onChange={(e) => setFName(e.target.value)} />
                        </Form.Group>
                        <Form.Group className="mb-3 col-12 col-md-4">
                            <Form.Label>Last Name *</Form.Label>
                            <Form.Control type="Text" placeholder="Last Name" value={lName} onChange={(e) => setLName(e.target.value)} />
                        </Form.Group>
                        <Form.Group className="mb-3 col-12 col-md-4">
                            <Form.Label>Phone Number *</Form.Label>
                            <Form.Control type="Text" placeholder="Phone Number" value={phone} onChange={(e) => setPhone(e.target.value)} />
                        </Form.Group>
                        <Form.Group className="mb-3 col-12 col-md-7">
                            <Form.Label>Email *</Form.Label>
                            <Form.Control type="Text" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
                        </Form.Group>
                        <Form.Group className="mb-3 col-12 col-md-5">
                            <Form.Label>Address *</Form.Label>
                            <Form.Control type="Text" placeholder="Address" value={address} onChange={(e) => setAddress(e.target.value)} />
                        </Form.Group>
                        <Form.Group className="mb-3 col-12 col-md-6">
                            <Form.Group className="mb-3">
                                <Form.Label>Choose A Photo</Form.Label>
                                <Form.Control id='file' accept='.jpg , .png , jpeg' type="file" onChange={onChangePase64} />
                            </Form.Group>
                        </Form.Group>
                        <Form.Group className="mb-3 col-12 col-md-6">
                            <Form.Label>Role *</Form.Label>
                            <Form.Select className="form-control" value={roleId} onChange={(e) => setRoleId(e.target.value)} >
                                <option value='Choose the state' disabled>  Choose the state </option>
                                {
                                    dataRoles.map((item, index) => {
                                        return (
                                            <option key={index} value={item.id}> {item.name} </option>
                                        )
                                    })
                                }
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-3 col-12 col-md-5">
                            <Form.Label>Status</Form.Label>
                            <div className="input-group mb-3">
                                <div className="input-group-text">
                                    <input defaultChecked={activeUser} onChange={(e) => setActivUser(!activeUser)} className="form-check-input mt-0" type="checkbox" value="" aria-label="Checkbox for following text input" />
                                </div>
                                <input type="text" className="form-control" aria-label="Text input with checkbox" disabled placeholder={activeUser ? 'Active' : 'Un Active'} />
                            </div>
                        </Form.Group>
                        <Form.Group className="mb-3 col-12 col-md-7">
                            <Form.Label>Password *</Form.Label>
                            <Form.Control type="Text" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
                        </Form.Group>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {
                        loading ? <button className="btn btn-primary" type="button" disabled>
                            <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                            Loading...
                        </button> : <Button variant="primary" disabled={loading} onClick={() => addUser()} >
                            Add User
                        </Button>
                    }
                </Modal.Footer>
            </Modal>
            <Modal show={showEdit} onHide={handleCloseEdit} className='modalAddUser'>
                <Modal.Header closeButton>
                    <Modal.Title>Edit User</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <Form.Group className="mb-3 col-12 col-md-4">
                            <Form.Label>First Name *</Form.Label>
                            <Form.Control type="Text" placeholder="First Name" value={fName} onChange={(e) => setFName(e.target.value)} />
                        </Form.Group>
                        <Form.Group className="mb-3 col-12 col-md-4">
                            <Form.Label>Last Name *</Form.Label>
                            <Form.Control type="Text" placeholder="Last Name" value={lName} onChange={(e) => setLName(e.target.value)} />
                        </Form.Group>
                        <Form.Group className="mb-3 col-12 col-md-4">
                            <Form.Label>Phone Number *</Form.Label>
                            <Form.Control type="Text" placeholder="Phone Number" value={phone} onChange={(e) => setPhone(e.target.value)} />
                        </Form.Group>
                        <Form.Group className="mb-3 col-12 col-md-7">
                            <Form.Label>Email *</Form.Label>
                            <Form.Control type="Text" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
                        </Form.Group>
                        <Form.Group className="mb-3  col-12 col-md-5">
                            <Form.Label>Address *</Form.Label>
                            <Form.Control type="Text" placeholder="Address" value={address} onChange={(e) => setAddress(e.target.value)} />
                        </Form.Group>
                        <Form.Group className="mb-3 col-12 col-md-6">
                            <Form.Group className="mb-3">
                                <Form.Label>Choose A Photo</Form.Label>
                                <Form.Control id='file' accept='.jpg , .png , jpeg' type="file" onChange={onChangePase64} />
                            </Form.Group>
                        </Form.Group>
                        <Form.Group className="mb-3 col-12 col-md-6">
                            <Form.Label>Role *</Form.Label>
                            <Form.Select className="form-control" value={roleId} onChange={(e) => setRoleId(e.target.value)} >
                                <option value='Choose the state' disabled>  Choose the state </option>
                                {
                                    dataRoles.map((item, index) => {
                                        return (
                                            <option key={index} value={item.id}> {item.name} </option>
                                        )
                                    })
                                }
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-3 col-12 col-md-12">
                            <Form.Label>Status</Form.Label>
                            <div className="input-group mb-3">
                                <div className="input-group-text">
                                    <input defaultChecked={activeUser} onChange={(e) => setActivUser(!activeUser)} className="form-check-input mt-0" type="checkbox" value="" aria-label="Checkbox for following text input" />
                                </div>
                                <input type="text" className="form-control" aria-label="Text input with checkbox" disabled placeholder={activeUser ? 'Active' : 'Un Active'} />
                            </div>
                        </Form.Group>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {
                        loading ? <button className="btn btn-primary" type="button" disabled>
                            <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                            Loading...
                        </button> : <Button variant="primary" disabled={loading} onClick={() => editUser()}>
                            Save Changes
                        </Button>
                    }
                </Modal.Footer>
            </Modal>
            {
                LoadState ? <Loading /> : <>
                    <button className="btn btn-primary" style={{ marginBottom: '12px' }} onClick={handleShowAdd}> Add User </button>
                    <DataTable
                        columns={columns}
                        data={filterDataUsers}
                        pagination
                        title='Users List'
                        fixedHeader
                        // selectableRows
                        // onSelectedRowsChange={(item) => console.table(item.selectedRows)}
                        selectableRowsHighlight
                        highlightOnHover
                        subHeader
                        subHeaderComponent={<input
                            type='text'
                            placeholder="Search"
                            className="form-control"
                            value={searchUserNameUsers}
                            onChange={(e) => setSearchUserNameUsers(e.target.value)}
                        />}
                    />
                </>
            }
        </div>
    );
}

export default Users;